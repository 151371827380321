import { ExtensionSlot, LayoutProps } from 'piral-core';
import * as React from 'react';
import { LegacyNavigationHandler } from '../infrastructure/legacy';

export const LayoutEmpty: React.FC<LayoutProps> = (props) => (
  <div className="layout-wrapper">
    <LegacyNavigationHandler />
    <div className="layout layout--empty">
      <main>{props.children}</main>
    </div>
  </div>
);
