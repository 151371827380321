import { useEffect } from "react";
import { getCurrentTenant } from "./tenant";
import { useHistory } from "react-router-dom";
import { getCurrentUser } from "./msal";

export function LegacyNavigationHandler() {
  const history = useHistory();

  useEffect(() => {
    async function handleNavigation(legacyPath) {
      try {
        const tenantId = (await getCurrentTenant()).id;

        const searchParams = new URLSearchParams(
          legacyPath.split("?")[1] || ""
        );
        const legacyTenantId = searchParams.get("Customer");
        const effectiveTenantId = legacyTenantId || tenantId;

        if (effectiveTenantId) {
          history.push(
            `/${effectiveTenantId}/legacy/${encodeURIComponent(legacyPath)}`
          );
          return;
        }
        history.push(`/iam/resourceselection`);
      } catch (error) {
        console.error("Legacy system integration error:", error);
      }
    }

    // Handle initial legacy URL access
    //   if (window.location.hostname === 'local-booking.casablanca.at' || localStorage.getItem("legacyMigrationTest") === "true") {
    //       localStorage.setItem("legacyMigrationTest", "false");
    //       handleNavigation(window.location.pathname);
    //   }

    // Handle navigation within legacy iframe
    const messageHandler = async (event) => {
      if (event.data.type === "navigation") {
        //NOTE: try without that, since we never really change to a completely different page in legacy -> in this case we might not need that. 
        //handleNavigation(event.data.path);
        
        // we rather just add the new iframes url as query param (mainly to create a new history entry in the browser and to differentiate the pages by the main apps url)
        const currentLocation = history.location;
        const searchParams = new URLSearchParams(currentLocation.search);
        searchParams.set('legacyPath', encodeURIComponent(event.data.path));

        history.push({
          pathname: currentLocation.pathname,
          search: searchParams.toString()
        });
      }
    };

    // Handle legacy system auth redirects
    const authHandler = (event) => {
      // TODO: readd that when we activate b2c in the legacy system
      // if (event.data.type === "AUTH_REQUIRED") {
      //   const loginUrl = "https://local-booking.casablanca.at/Account/Login";
      //   const returnUrl = encodeURIComponent(window.location.href);
      //   window.location.href = `${loginUrl}?ReturnUrl=${returnUrl}`;
      // }
    };

    const loadedHandler = async (event) => {
      if (event.data.type === "loaded") {
        const legacyLoadedEvent = new CustomEvent('legacyContentLoaded', {
          bubbles: true,
          detail: { timestamp: Date.now() }
        });
        window.dispatchEvent(legacyLoadedEvent);

        const user = await getCurrentUser();
        const persistedSettings = localStorage.getItem(`${user.id}.userSettings`);
        let darkModeActive = window.matchMedia('(prefers-color-scheme: dark)').matches;
        if (persistedSettings) {
          try {
            const userSettings = JSON.parse(JSON.parse(persistedSettings));
            if (userSettings["global.global.darkModeActive"]?.Value !== undefined) {
              darkModeActive = userSettings["global.global.darkModeActive"].Value === "true";
            }
          } catch (error) {
            console.error("Error parsing user settings:", error);
          }
        }

        document.querySelectorAll('iframe').forEach(iframe => {
          iframe.contentWindow.postMessage(
            {
              "type": "informLegacy",
              "data":{
                "darkModeActive": darkModeActive,
              }
            },
            '*'
          );
        });
      }
    };

    window.addEventListener("message", messageHandler);
    window.addEventListener("message", authHandler);
    window.addEventListener("message", loadedHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
      window.removeEventListener("message", authHandler);
      window.removeEventListener("message", loadedHandler);
    };
  }, [history]);

  return null;
}
