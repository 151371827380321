import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { PiletEntries, StateContext } from 'piral-core';

const extractIdentifier = (pathname: string): string => {
  // Remove leading slash and split path
  const parts = pathname.replace(/^\//, '').split('/');
  
  const pilets: PiletEntries = window.piralInstance.getData('pilets') || [];
  const piletIdentifiers = pilets.map(p => p.name.replace('@casablanca/', ''));
console.log("pilets: ", pilets);
  if (parts.length >= 2) {
    
    // Check if second part is a valid pilet identifier
    if (piletIdentifiers.includes(parts[1])) {
      return parts[1];
    }
    // If not, check if first part is a valid identifier
    if (piletIdentifiers.includes(parts[0])) {
      return parts[0];
    }
  }
  
  if (parts.length === 1 && parts[0]) {
    if (piletIdentifiers.includes(parts[0])) {
      return parts[0];
    }
  }
  
  return '';
};

export const RouteChangeEmitter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const piralInstance = React.useContext(StateContext);
  const previousLocation = React.useRef(location);

  React.useEffect(() => {
    piralInstance.emit('route-changed', {
      targetPathname: location.pathname,
      targetSearch: location.search,
      targetHash: location.hash,
      targetIdentifier: extractIdentifier(location.pathname),
      previousPathname: previousLocation.current.pathname,
      previousSearch: previousLocation.current.search,
      previousHash: previousLocation.current.hash,
      previousIdentifier: extractIdentifier(previousLocation.current.pathname)
    });
    
    previousLocation.current = location;
  }, [location, piralInstance]);

  return <>{children}</>;
};