import { Tenant } from '../types/globals';

async function getCurrentTenant (): Promise<Tenant | null> {
  const currentTenant = localStorage.getItem("currentTenant")
  if (currentTenant && currentTenant !== "") {
    const parsedTenant: Tenant = await JSON.parse(currentTenant);
    parsedTenant.id = parsedTenant.id.toLowerCase();
    return parsedTenant;
  }
  return null;
};

function setCurrentTenant(tenant: Tenant) {
  if(tenant){
    localStorage.setItem("currentTenant", JSON.stringify(tenant))
  }
};

window.setCurrentTenant = setCurrentTenant;
window.getCurrentTenant = getCurrentTenant;

export { getCurrentTenant, setCurrentTenant }