import userflow from 'userflow.js'
import { User } from '../types/globals';
import { getCurrentTenant } from '../infrastructure/tenant';
import { env } from '../infrastructure/environment';

// Docs: https://docs.userflow.com/docs/userflow-js#userflow-js-reference

userflow.init(env.USERFLOW_TOKEN)

async function identifyUser(user: User){
  userflow.identify(
    user.b2cObjectId,
    {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      locale_code: navigator.language,
      device_type: window.innerWidth > 800 ? 'desktop' : 'mobile'
    }
  );
  const currentTenant = await getCurrentTenant();
  if(currentTenant)
  {
    userflow.group(currentTenant.id);
  }
}

export { identifyUser }